import {
  AccountType,
  UserInfoType,
  WalletType,
  Thresholds,
  Threshold,
} from 'shared/types';
import { CROSS_WALLET_SUPPORTED } from './constants';
import { TransferParticipant } from './types';
import {
  aboutToExceedIncomingLimit,
  aboutToExceedLimit,
  aboutToExceedSingleLimit,
  isNoVoteTx,
  userCanExceedIncomingLimit,
} from 'shared/helpers/transaction';
import { formatAmountPrecise } from 'features/Multisig/helpers';

export const checkIsFromWallet = (from?: TransferParticipant) => {
  return from?.account && from?.account?.exchange === 'WALLETS';
};

export const checkIsFromMultisig = (from?: TransferParticipant) => {
  return checkIsFromWallet(from) && from?.account?.is_multisig;
};

export const checkIsCrossWallet = (
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  return (
    checkIsSubacc(from, to) ||
    (from?.account?.id === to?.account?.id &&
      [from?.account?.exchange, to?.account?.exchange].every(
        (exchange) => exchange && CROSS_WALLET_SUPPORTED.includes(exchange),
      ))
  );
};

export const checkAreBothFutures = (
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  return [from?.wallet?.type, to?.wallet?.type].every((type) =>
    type?.includes('futures'),
  );
};

export const checkIsDepositAddressWhitelisted = (
  wallet?: WalletType,
  network?: string,
  whitelists?: any,
  currency?: string,
) => {
  const address: any = wallet?.deposit_addresses?.find(
    (address: any) => address.network === network,
  );
  const whitelist = whitelists.find(
    (whitelist: any) =>
      whitelist.deposit_address === address?.deposit_address &&
      whitelist.network === network &&
      currency === whitelist.currency,
  );
  return whitelist;
};

export const checkIsShowFees = (
  isInternal: boolean,
  isFromWallet?: boolean,
  minWithdrawLimit?: string | null,
  withdrawFee?: string | null,
  depositAddressIsWhitelisted?: boolean,
) => {
  return Boolean(
    depositAddressIsWhitelisted &&
      !isFromWallet &&
      !isInternal &&
      (minWithdrawLimit || withdrawFee),
  );
};

export const checkIsInternal = (
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  return from?.account?.id === to?.account?.id || checkIsSubacc(from, to);
};

export const checkIsBinanceNotAvailableFrom = (
  isBetweenSubaccs: boolean,
  isBinanceCross: boolean,
  from: any,
) => {
  return (
    !isBetweenSubaccs &&
    !isBinanceCross &&
    from?.account?.exchange === 'BINANCE' &&
    ['margin', 'usdm-futures', 'coinm-futures'].includes(from?.wallet?.type)
  );
};

export const checkIsBetweenSubaccounts = (
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  return (
    from?.account?.master_id !== null &&
    from?.account?.master_id !== undefined &&
    from?.account?.master_id === to?.account?.master_id
  );
};

export const checkIsSubacc = (
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  return (
    from?.account?.master_id === to?.account?.id ||
    to?.account?.master_id === from?.account?.id
  );
};

export const getWithdrawFee = (
  from?: TransferParticipant,
  network?: string,
) => {
  return from?.account?.exchange_transfer_info?.fee?.[from.wallet.currency]?.[
    network as string
  ]?.[0];
};

export const getMinWithdrawLimit = (
  from?: TransferParticipant,
  network?: string,
) => {
  return from?.account?.exchange_transfer_info?.withdrawal?.[
    from.wallet.currency
  ]?.[network as string]?.[0];
};

export const checkIsInsufficientFunds = (
  amount: number,
  from?: TransferParticipant,
) => {
  return amount > 0 && (Number(from?.wallet?.available) || 0) < amount;
};

export const getTransferError = (
  amount: number,
  rates: any,
  user: UserInfoType | null,
  network?: string,
  from?: TransferParticipant,
  to?: TransferParticipant,
) => {
  const isTransfer = from?.account.id === to?.account.id;
  const insufficientFunds = checkIsInsufficientFunds(amount, from);
  const noVoteTx = isNoVoteTx(from?.account, to?.account);
  const exceedsLimit =
    aboutToExceedLimit(amount, from?.wallet?.currency, user, rates) &&
    !noVoteTx;
  const exceedsSingleLimit =
    aboutToExceedSingleLimit(amount, from?.wallet?.currency, user, rates) &&
    !noVoteTx;
  const exceedsIncomingLimit = Boolean(
    to?.account &&
      to?.account?.id !== from?.account?.id &&
      aboutToExceedIncomingLimit(
        amount,
        to?.account?.incoming_limit?.used,
        to?.account?.incoming_limit?.limit,
        to?.wallet?.currency,
        user,
        rates,
      ),
  );
  const currency = from?.wallet?.currency;
  const maxAmount =
    from?.account?.exchange_transfer_info?.withdrawal?.[currency as string]?.[
      network as string
    ]?.[1];
  const minAmount =
    from?.account?.exchange_transfer_info?.withdrawal?.[currency as string]?.[
      network as string
    ]?.[0];
  const exceedsMax =
    !isTransfer &&
    maxAmount !== null &&
    maxAmount !== undefined &&
    Number(amount) > Number(maxAmount);
  const exceedsMin =
    !isTransfer &&
    minAmount !== null &&
    minAmount !== undefined &&
    Number(amount) < Number(minAmount);
  const canExceedIncomingLimit = userCanExceedIncomingLimit(user);

  if (insufficientFunds) {
    return 'Insufficient funds';
  } else if (exceedsMax) {
    return `The transaction amount exceeds the exchange's maximum transaction limit: ${parseFloat(
      maxAmount,
    )} ${currency}. Please enter a correct amount.`;
  } else if (exceedsMin) {
    return `The transaction amount is insufficient due to the exchange's minimum  transaction limit: ${parseFloat(
      minAmount,
    )} ${currency}. Please enter a correct amount.`;
  } else if (exceedsIncomingLimit) {
    return `This transaction will exceed the incoming limit of ${
      to?.account.name
    } account (${to?.account.incoming_limit.limit} USD)${
      canExceedIncomingLimit ? ', but you can still create the transaction' : ''
    }.`;
  } else if (exceedsLimit) {
    const type = user?.period_limits?.WEEK ? 'WEEK' : 'DAY';
    const displayType = type === 'WEEK' ? 'weekly' : 'daily';
    const limit = Number(user?.period_limits?.[type]);
    return `You can't send this transaction because you have exceeded your ${displayType} transaction limit: $${parseFloat(
      String(limit),
    )}. Please check your amount and try again or reach out to your administrator to increase your limit.`;
  } else if (exceedsSingleLimit) {
    const singleTxLimit = user?.single_transaction_limit;
    return `You can't send this transaction because it exceeds your maximum transaction limit: $${parseFloat(
      String(singleTxLimit),
    )}. Please check your amount and try again or reach out to your administrator to increase your limit.`;
  }
};

export const getViolatedCurrencies = (account: AccountType) => {
  const thresholds = account.thresholds;
  const totalThreshold = thresholds?.total;
  const specificThreshold = thresholds?.specific;

  if (!totalThreshold && !specificThreshold) return [];

  const allThresholds = { ...totalThreshold, ...specificThreshold };
  const thresholdCurrencies = [
    // @ts-ignore
    ...Object.keys(totalThreshold),
    // @ts-ignore
    ...Object.keys(specificThreshold),
  ];

  return thresholdCurrencies.filter(
    // @ts-ignore
    (curr) => allThresholds[curr].is_violated,
  );
};

export function formatAmount(
  value: number,
  minAmount: number,
  maxAmount: number,
): number {
  return Math.min(Math.max(value, minAmount), maxAmount);
}

export function calculateCurrentThreshold(threshold: Threshold): number {
  const { min_amount, max_amount, current_amount } = threshold;
  if (current_amount < min_amount) {
    return min_amount;
  } else if (current_amount > max_amount) {
    return max_amount;
  } else {
    return current_amount;
  }
}

export function formatNumber(number: number): string {
  const integerPart = Math.floor(number);
  return integerPart.toLocaleString();
}

export function generateThresholdExceededMessage(
  thresholds: Thresholds,
): string {
  const totalThreshold = thresholds.total && thresholds.total.USD;
  const specificThresholds = Object.fromEntries(
    Object.entries(thresholds.specific).filter(
      ([, value]) => value?.is_violated,
    ),
  );

  const totalMessage = totalThreshold
    ? `The total amount (${formatNumber(totalThreshold.current_amount)} USD)`
    : '';

  const specificMessages = Object.entries(specificThresholds).map(
    ([asset, threshold]) => {
      const currentAmount = threshold && threshold?.current_amount;
      return `${asset} (${currentAmount} ${asset})`;
    },
  );

  const endThresholdMessages = Object.entries(specificThresholds).map(
    ([asset, threshold], index, arr) => {
      if (threshold) {
        const formattedAmount = calculateCurrentThreshold(threshold);

        const dynamicAnd =
          totalThreshold && Object.entries(specificThresholds)?.length > 0
            ? 'and'
            : '';

        const singleRes = ` ${dynamicAnd} ${asset} threshold (${formattedAmount} ${asset})`;

        const dynamicComma =
          !totalThreshold && arr.length > 1 && index === 0 ? '' : ',';

        const andOrComma =
          index === 0 && totalThreshold ? ' and' : dynamicComma;

        const lastString = arr.length - 1 === index ? ' thresholds' : '';

        return arr.length === 1
          ? singleRes
          : `${andOrComma} ${asset} (${formattedAmount} ${asset})${lastString}`;
      }
      return '';
    },
  );

  let message = `${totalMessage}${
    totalMessage && specificMessages.length > 0 ? ' and the' : ''
  }${
    specificMessages.length > 0
      ? `${
          totalMessage && specificMessages.length > 0 ? '' : 'The'
        } total value of ` + specificMessages.join(', ')
      : ''
  }${totalMessage ? ' in your account' : ' in your account has exceeded'}`;

  if (totalThreshold) {
    const formattedTotalAmount = formatAmount(
      totalThreshold.current_amount,
      totalThreshold.min_amount,
      totalThreshold.max_amount,
    );

    message += ` has exceeded the total threshold (${formatAmountPrecise(
      formattedTotalAmount,
      8,
    )} USD)`;
  }

  if (endThresholdMessages.length > 0) {
    message += `${endThresholdMessages.join('')}`;
  }

  return message;
}

import { FC, forwardRef, useRef } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { getExchangeLogoUrl } from 'shared/constants/exchanges';
import { convertExpToDecimal } from 'shared/helpers/format';

import * as S from '../Modal/styled';

export const digitsNumber = 6;

interface CodeInputProps {
  autoFocus?: boolean;
  required?: boolean;
  onChange: any;
  onReset: any;
  errorText: string | null;
  title: string;
  value: string;
  noMargin?: boolean;
  responsive?: boolean;
}

export const CodeInput = forwardRef(
  (
    {
      required = true,
      onChange,
      onReset,
      errorText,
      title,
      autoFocus,
      value,
      noMargin,
      responsive = false,
    }: CodeInputProps,
    ref,
  ) => {
    if (!required) return null;

    return (
      <>
        <S.CodeHeader>
          <S.OtpText responsive={responsive}>{title}</S.OtpText>
          {value && <S.OtpReset responsive={responsive} onClick={onReset}>Reset</S.OtpReset>}
        </S.CodeHeader>
        <S.OtpCodeWrap noMargin={noMargin}>
          {/* @ts-ignore */}
          <ReactCodeInput
            autoFocus={autoFocus}
            // @ts-ignore
            ref={ref}
            className={`otp ${errorText ? 'error' : ''}`}
            type="number"
            fields={digitsNumber}
            onChange={onChange}
          />
        </S.OtpCodeWrap>
      </>
    );
  },
);

interface SubmitButtonProps {
  isSubmit: boolean;
  errorText: string | null;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  isSubmit,
  errorText,
}) => {
  if (isSubmit && !errorText) return <>Submitting…</>;

  return (
    <>
      Submit <S.IconArrow />
    </>
  );
};

interface OtpProposalDetailsProps {
  amount: string;
  currency: string;
  fromAccount: any;
  toAccount: any;
}

export const OtpProposalDetails: FC<OtpProposalDetailsProps> = ({
  amount,
  currency,
  fromAccount,
  toAccount,
}) => {
  const fromLogo = getExchangeLogoUrl(fromAccount?.exchange);
  const toLogo = getExchangeLogoUrl(toAccount?.exchange);
  const fromAccountName = fromAccount?.name
    ? fromAccount.name
    : 'Unavailable account';
  const toAccountName = toAccount?.name
    ? toAccount?.name
    : 'Unavailable account';
  const fromAccountExchange = fromAccount?.exchange
    ? fromAccount?.exchange
    : '';
  const toAccountExchange = toAccount?.exchange ? toAccount?.exchange : '';

  return (
    <S.OtpProposalDetailsWrap>
      <S.OtpAmount>
        {convertExpToDecimal(Number(amount))} {currency}
      </S.OtpAmount>
      <S.OtpAccount>
        <S.OtpAccountName unavailable={!fromAccount?.name}>
          {fromAccountName}
        </S.OtpAccountName>
        <S.OtpAccountExchange>
          {fromLogo && (
            <S.OtpAccountExchangeIcon
              style={{
                backgroundImage: `url(${fromLogo})`,
              }}
            />
          )}
          {fromAccountExchange}
        </S.OtpAccountExchange>
      </S.OtpAccount>
      <S.OtpDirectionIcon />
      <S.OtpAccount>
        <S.OtpAccountName unavailable={!toAccount?.name}>
          {toAccountName}
        </S.OtpAccountName>
        <S.OtpAccountExchange>
          {toLogo && (
            <S.OtpAccountExchangeIcon
              style={{
                backgroundImage: `url(${toLogo})`,
              }}
            />
          )}
          {toAccountExchange}
        </S.OtpAccountExchange>
      </S.OtpAccount>
    </S.OtpProposalDetailsWrap>
  );
};

import { action, makeObservable, runInAction } from 'mobx';

import { requestGet } from 'shared/axios';

import { ISettingsStore } from '../models';
import { AppSettingsType } from 'shared/types';
import { setBaseUrl } from '@gnosis.pm/safe-react-gateway-sdk';

const defaultSettings: AppSettingsType = {
  application_direct_link: '',
  application_playmarket_link: '',
  currencies_primary: [],
  currencies_icons: {},
  currencies_networks: {},
  currencies_secondary: [],
  gnosis_node: '',
  gnosis_chain_id: '',
  google_auth_key: '',
  proposal_votes_value_to_approve: 3,
  proposal_votes_value_to_reject: 0,
  is_hotp_enabled: false,
  gnosis_enabled: false,
  overview_decimals: {
    default: { crypto: 0, fiat: 0, usd_k: 0, usd_m: 0 },
    BTC: 5,
  },
  sendfunds_decimals: {
    default: { crypto: 0, fiat: 0, usd_k: 0, usd_m: 0 },
    BTC: 5,
  },
};

const hasSlashAtEnd = (url: string) => url[url.length - 1] === '/';

const parseLastSlash = (url: string) => url.slice(0, url.length - 1);

class SettingsStore implements ISettingsStore {
  constructor() {
    makeObservable(this);
  }

  appSettings: AppSettingsType = { ...defaultSettings };

  @action.bound public async fetchAppSettings() {
    try {
      const rawSettings = await requestGet<AppSettingsType>('/settings/');
      runInAction(() => {
        this.appSettings = rawSettings.data;
        const url = rawSettings.data.gnosis_node;
        // setBaseUrl('https://safe-client.gnosis.io');
        setBaseUrl(hasSlashAtEnd(url) ? parseLastSlash(url) : url);
      });
    } catch (e) {
      console.log('get settings error:', e);
    }
  }
}

export const SettingsStoreInstance = new SettingsStore();

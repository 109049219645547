import styled, { css, keyframes } from 'styled-components/macro';
import QRCode from 'qrcode.react';

import { ReactComponent as SvgNo } from 'shared/assets/icons/icon-no.svg';
import { ReactComponent as SvgYes } from 'shared/assets/icons/icon-check.svg';
import { ReactComponent as SvgQR } from 'shared/assets/icons/icon-qr-min.svg';
import { ReactComponent as SvgSettings } from 'shared/assets/icons/icon-settings.svg';
import { ReactComponent as SvgLoader } from 'shared/assets/icons/icon-preloader.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const Data = styled.div`
  flex: 1;
  min-width: 0;
  margin-bottom: 0px;
`;
export const Label = styled.div`
  display: flex;
  color: var(--object-secondary, #80899d);
  font-family: Lato;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  user-select: none;
  margin: 0 10px 0 0;
  text-transform: uppercase;

  @media (min-width: 1600px) {
    font-size: calc(9px * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(9px * 1.5);
  }
`;
export const Row = styled.div`
  display: flex;
  margin: 0 0 10px 0;
  align-items: center;

  ${Label} {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
  }

  @media (min-width: 1600px) {
    margin: 0 0 calc(10px * 1.25) 0;
  }
  @media (min-width: 2160px) {
    margin: 0 0 calc(10px * 1.5) 0;
  }
`;
export const AdressWrap = styled.div`
  padding: 20px;
  margin: 0;
  border-radius: 5px;
  border: 1px solid var(--border, #d9dfe7);
  background: var(--highlight, #f6f8f9);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (min-width: 1600px) {
    padding: calc(16px * 1.25);
  }
  @media (min-width: 2160px) {
    padding: calc(16px * 1.5);
  }
`;
export const AdressHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px 0;
  width: 100%;
`;
export const AddressRow = styled.div<{ isLast?: boolean }>`
  margin: ${(props) => (props.isLast ? '0;' : '0 0 5px 0;')}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const AddressTools = styled.div`
  display: flex;

  button {
    color: var(--placeholder, #b9bec8);
    &:hover {
      color: var(--object-secondary, #b9bec8);
    }
  }
`;
export const Button = styled.button`
  border: none;
  background: 0;
  padding: 0;
  margin: 0 0 0 10px;
  display: flex;
`;
const IconStyle = css`
  width: 14px;
  height: 14px;
`;
export const IconQR = styled(SvgQR)<{ isQrShown: boolean }>`
  ${IconStyle}
  fill: currentColor;

  ${(props) =>
    props.isQrShown &&
    css`
      fill: var(--object-secondary, #b9bec8);
    `};
`;
export const IconSettings = styled(SvgSettings)`
  ${IconStyle}
  stroke: currentColor;
`;
export const Address = styled.div`
  font-family: Roboto Mono;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--object-primary, #00143c);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 1600px) {
    font-size: calc(12px * 1.25);
  }
  @media (min-width: 2160px) {
    font-size: calc(12px * 1.5);
  }
`;
export const Tabs = styled.nav`
  display: flex;
  flex-wrap: wrap;
`;
export const TabItem = styled.div<{ activeTab?: boolean }>`
  cursor: pointer;
  line-height: 14px;
  padding: 3px 6px;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 700;
  border-radius: 10px;
  background: var(--multik-lt-background, #eceff4);
  color: var(--multik-lt-object-primary, #00143c);
  margin: 0 5px 0px 0;

  ${(props) =>
    props.activeTab &&
    css`
      background: var(--multik-lt-accent-primary, #0064ff);
      color: var(--multik-lt-dark-bg, #fff);
    `};

  @media (min-width: 1600px) {
    font-size: calc(9px * 1.25);
    padding: calc(3px * 1.25) calc(6px * 1.25);
    margin: 0 calc(5px * 1.25) 0px 0;
  }
  @media (min-width: 2160px) {
    font-size: calc(9px * 1.5);
    padding: calc(3px * 1.5) calc(6px * 1.5);
    margin: 0 calc(5px * 1.5) 0px 0;
  }
`;
export const IconMin = styled.div`
  width: 10px;
  min-width: 10px;
  height: 10px;
  background-size: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  margin: 0 5px 0 0;

  @media (min-width: 1600px) {
    width: calc(10px * 1.25);
    min-width: calc(10px * 1.25);
    height: calc(10px * 1.25);
    margin: 0 calc(5px * 1.25) 0 0;
    background-size: calc(10px * 1.25);
  }

  @media (min-width: 2160px) {
    width: calc(10px * 1.5);
    min-width: calc(10px * 1.5);
    height: calc(10px * 1.5);
    margin: 0 calc(5px * 1.5) 0 0;
    background-size: calc(10px * 1.5);
  }
`;
export const Chain = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
`;
export const ChainItem = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 0 5px 0 0;
  font-family: Roboto Mono;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &:before {
    content: '/';
    color: var(--border, #d9dfe7);
    margin: 0 5px 0 0;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }

  @media (min-width: 1600px) {
    font-size: calc(12px * 1.25);
  }

  @media (min-width: 2160px) {
    font-size: calc(12px * 1.5);
  }
`;
const TextCssBlockCss = css`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin: 0 0 5px 0;
  vertical-align: top;

  @media (min-width: 1600px) {
    font-size: calc(10px * 1.25);
  }
  @media (min-width: 2160px) {
    font-size: calc(10px * 1.5);
  }
`;
export const Error = styled.div`
  color: var(--error, #cf3a1f);
  ${TextCssBlockCss}
`;
export const Success = styled.div`
  color: var(--success, #1bb560);
  ${TextCssBlockCss}
`;
export const Note = styled.div`
  color: var(--object-secondary, #80899d);
  ${TextCssBlockCss}

  a {
    color: var(--accent-primary, #0064ff);
    text-decoration: none;
  }
`;
export const Check = styled.button`
  color: var(--accent-primary, #0064ff);
  ${TextCssBlockCss}
  font-weight: 700;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;
export const Whitelist = styled.div`
  display: flex;
  align-items: center;
`;
export const IconNo = styled(SvgNo)`
  ${IconStyle}
  stroke: var(--error, #cf3a1f);
`;
export const IconYes = styled(SvgYes)`
  ${IconStyle}
  stroke: var(--success, #1BB560);
`;
export const ButtomLink = styled.a`
  display: block;
  margin: 20px 0 0 0;
  color: var(--multik-lt-accent-primary, #0064ff);
  font-size: 12px;
  font-weight: 700;
`;
export const LoaderIcon = styled(SvgLoader)`
  ${IconStyle}
  margin: 0 0 0 10px;
  animation: ${rotate} 1s linear infinite;
`;
export const Loader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: Roboto Mono;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--object-primary, #00143c);
`;

export const QrCodeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;
// @ts-ignore
export const QrCodeVisual = styled(QRCode)`
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  background: var(--base);
`;

export const ChainItemOverflow = styled(ChainItem)`
  flex: 1;
  overflow: hidden;
`;
export const ChainItemOverflowText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Amount = styled.div`
  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: 400;
`;

export const Info = styled.div`
  font-size: 12px;

  @media (min-width: 1600px) {
    font-size: calc(12px * 1.25);
  }
  @media (min-width: 2160px) {
    font-size: calc(12px * 1.5);
  }
`;

export const AmountValue = styled.div`
  margin-right: 8px;
  letter-spacing: 1px;
`;

export const AmountIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 0px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
`;
